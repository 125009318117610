import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import UpgradeSuccess from "./UpgradeSuccess";
import FailurePopup from "./FailurePopup";
import { CircularProgress, Dialog, DialogContent } from "@mui/material"; // Import Switch from MUI
import AcceptCouponFromUser from "../../../Components/AcceptCouponFromUser";
import { useConfettiTrigger } from "../../../Components/Confetti";
import EnterpriseContactForm from "./EnterpriseContactForm";

const AVAILABLE_TIME_PERIODS = ["monthly", "annual", "lifetime"];
const AVAILABLE_PLANS = ["e-Sign Lite", "e-Sign Pro", "e-Sign Enterprise"];

const ENABLED_TIME_PERIODS = ["monthly", "annual"]; // Example: ["monthly", "annual"]
const ENABLED_PLANS = ["e-Sign Pro", "e-Sign Enterprise"]; // Example: ["e-Sign Lite", "e-Sign Pro"]

const pricingData = {
  pricing: {
    lifetime: {
      "e-Sign Lite": "$99.99",
      "e-Sign Pro": "$199.99",
      "e-Sign Enterprise": "$499.99",
    },
    annual: {
      "e-Sign Lite": "$4.99",
      "e-Sign Pro": "$4.99",
      "e-Sign Enterprise": "Custom",
    },
    monthly: {
      "e-Sign Lite": "$9.99",
      "e-Sign Pro": "$9.99",
      "e-Sign Enterprise": "Custom",
    },
  },
  features: {
    "e-Sign Lite": [
      "Signatures Per Month: unlimited",
      "Signed Contracts on Platform: 300",
      "Advanced signer authentication",
      "Real-time updates & Reminders",
      "Send automatic reminders for pending signatures",
      "Enable signing on both desktop and mobile devices",
      "Track document status in real-time",
      "Document status notifications and updates",
      "Secure encryption of signed documents",
    ],
    "e-Sign Pro": [
      "Signatures Per Month: unlimited",
      "Signed Contracts on Platform: 500",
      "Advanced signer authentication",
      "Real-time updates & Reminders",
      "Send automatic reminders for pending signatures",
      "Enable signing on both desktop and mobile devices",
      "Track document status in real-time",
      "Secure encryption of signed documents",
    ],
    "e-Sign Enterprise": [
      "All e-Sign Pro Features included",
      "Signed Contracts on Platform: custom",
      "Premium Support",
      "Customizable Signing Workflows",
      "Document status notifications and updates",
      "Upload and use your own templates",
      "Secure encryption of signed documents",
      "Integration with Enterprise Software",
    ],
  },
};

const PricingCard = ({
  plan,
  price,
  features,
  billingType,
  openPopupForPlan,
  createCheckoutSession,
}) => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [userCount, setUserCount] = useState(1);

  const handleButtonClick = () => {
    if (plan === "e-Sign Enterprise") {
      setShowContactForm(true);
    } else {
      createCheckoutSession(plan, billingType, userCount || 1);
    }
  };

  return (
    <div className="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10 w-full max-w-[400px] mx-auto">
      <div className="p-8 sm:p-10">
        <h3 className="text-lg font-semibold text-black">{plan}</h3>
        <button
          className="mt-8 w-full rounded-full bg-[#06526D] border border-[#06526D] px-4 py-2.5 text-center text-sm font-semibold text-white hover:bg-white hover:text-[#06526D] transition cursor-pointer"
          onClick={handleButtonClick}
        >
          {plan === "e-Sign Enterprise" ? "Contact Us" : "Buy Now"}
        </button>
        <p className="mt-4 text-5xl font-semibold text-gray-900 dark:text-gray-200">
          {plan === "e-Sign Pro"
            ? `$${(parseFloat(price.replace("$", "")) * userCount).toFixed(2)}`
            : price}
        </p>
        <p className="text-lg text-gray-500 dark:text-gray-400">
          {plan === "e-Sign Enterprise"
            ? ""
            : billingType === "monthly"
            ? "/month"
            : billingType === "annual"
            ? "/month when paid annually"
            : "one time"}
          {plan === "e-Sign Pro" && userCount > 1 && ` for ${userCount} users`}
        </p>

        {plan === "e-Sign Pro" && (
          <div className="mt-4 mb-6 flex flex-col items-center">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Number of Users
            </label>
            <div className="flex items-center justify-center space-x-3">
              <button
                onClick={() => setUserCount(Math.max(1, userCount - 1))}
                className="p-1.5 rounded-md bg-gray-100 hover:bg-gray-200 text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <input
                type="number"
                min="1"
                value={userCount}
                onChange={(e) => setUserCount(Math.max(1, parseInt(e.target.value) || 1))}
                className="w-24 text-center border rounded-md p-1.5 text-gray-900 dark:text-gray-100"
              />
              <button
                onClick={() => setUserCount(userCount + 1)}
                className="p-1.5 rounded-md bg-gray-100 hover:bg-gray-200 text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}

        <ul className="mt-6 space-y-3">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-[20px] w-[20px] text-teal-600"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
              </svg>
              <span className="ml-3 text-[13px] text-gray-600 text-left dark:text-gray-300">
                {feature}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <EnterpriseContactForm isOpen={showContactForm} onClose={() => setShowContactForm(false)} />
    </div>
  );
};

const PricingCardSection = () => {
  const handleConfettiTrigger = useConfettiTrigger();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedPlanPeriod, setSelectedPlanPeriod] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openFailurePopup, setOpenFailurePopup] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [billingType, setBillingType] = useState(() => {
    // Set default billing type to the first enabled time period
    return ENABLED_TIME_PERIODS.includes("annual") ? "annual" : ENABLED_TIME_PERIODS[0];
  });

  const [searchParams] = useSearchParams();
  const paymentSuccess = searchParams.get("payment_success");

  const openPopupForPlan = (plan, period) => {
    setSelectedPlan(plan);
    setSelectedPlanPeriod(period);
    setPopupOpen(true);
  };

  const closePopup = () => setPopupOpen(false);

  const createCheckoutSession = async (planType, planPeriod, userCount = 1) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_APIS_BASE_URL}/apis/payment/createCheckoutSession`,
        {
          planType,
          planPeriod,
          quantity: userCount,
          cancelUrl: `${window.location.href}?payment_success=false`,
        }
      );

      if (response.status === 200) {
        window.location.href = response.data.url;
      } else {
        console.error("Failed to create checkout session:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    } finally {
      setIsLoading(false);
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    if (paymentSuccess === "true") {
      setOpenSuccessPopup(true);
      handleConfettiTrigger();
    } else if (paymentSuccess === "false") {
      setOpenFailurePopup(true);
    }
  }, [paymentSuccess]);

  const renderPricingCards = (billingType, createCheckoutSession) => (
    <div
      key={billingType}
      className={`mx-auto mb-[50px] max-w-[1600px] ${
        billingType === "lifetime"
          ? "bg-gradient-to-l from-gray-200 via-fuchsia-200 to-stone-100 py-[40px] px-[20px] 610sm:px-[100px] rounded-[40px] shadow section-ribbon"
          : "rounded-[40px] border py-[40px] px-[20px] 610sm:px-[100px]"
      }`}
    >
      <p className="text-gray-700 font-light text-[12px] 968sm:text-[16px] text-center">
        Looking for Legitt AI Pricing?{" "}
        <a
          href="https://legittai.com/pricing"
          className="text-primary-main"
          style={{ textDecoration: "underline" }}
        >
          Click here
        </a>
      </p>
      {billingType === "lifetime" && (
        <div className="ribbon-container">
          <span>Lifetime</span>
        </div>
      )}
      <h3
        className={`text-[10px] 610sm:text-[20px] font-medium capitalize ${
          billingType === "lifetime" ? "text-black" : "text-black"
        }`}
      >
        {billingType === "monthly" && <div>{billingType} billing</div>}
        {billingType === "annual" && <div>{billingType} billing</div>}
        {billingType === "lifetime" && (
          <div className="text-[20px] 610sm:text-[40px] font-bold text-black">
            Pay once, use forever.
          </div>
        )}
      </h3>
      <div
        className={`grid gap-8 mt-8 ${
          ENABLED_PLANS.length === 1
            ? "grid-cols-1 max-w-2xl mx-auto"
            : ENABLED_PLANS.length === 2
            ? "grid-cols-1 md:grid-cols-2 max-w-4xl mx-auto"
            : "grid-cols-1 md:grid-cols-2 xl:grid-cols-3"
        } justify-items-center`}
      >
        {Object.entries(pricingData.pricing[billingType])
          .filter(([plan]) => ENABLED_PLANS.includes(plan))
          .map(([plan, price]) => (
            <PricingCard
              key={plan}
              plan={plan}
              price={price}
              features={pricingData.features[plan]}
              billingType={billingType}
              openPopupForPlan={openPopupForPlan}
              createCheckoutSession={createCheckoutSession}
            />
          ))}
      </div>
    </div>
  );

  return (
    <div className="bg-white dark:bg-gray-800 px-6 lg:px-8">
      <div className="text-center mb-2">
        <h2 className="text-lg font-medium text-black">Electronic-Signature Pricing</h2>
      </div>

      {/* Updated toggle switch section to show only enabled time periods */}
      {ENABLED_TIME_PERIODS.length > 1 && (
        <div className="flex justify-center items-center mb-2">
          <label className="bg-[#f5f5f5] shadow-two relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1">
            {ENABLED_TIME_PERIODS.map((period) => (
              <span
                key={period}
                className={`flex items-center space-x-[6px] mx-1 rounded py-2 px-[18px] text-sm font-medium transition-all duration-300 ${
                  billingType === period ? "bg-blue-500 text-white" : "text-primary"
                }`}
                onClick={() => setBillingType(period)}
              >
                {period.charAt(0).toUpperCase() + period.slice(1)}
              </span>
            ))}
          </label>
        </div>
      )}

      {/* Render Pricing Cards based on selected billing type */}
      {renderPricingCards(billingType, createCheckoutSession)}

      {/* {isPopupOpen && (
        <Dialog open={isPopupOpen} onClose={closePopup}>
          <DialogContent>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <CircularProgress />
              </div>
            ) : (
              <AcceptCouponFromUser
                setSelectedPlanPeriod={setSelectedPlanPeriod}
                selectedPlanPeriod={selectedPlanPeriod}
                selectedPlan={selectedPlan}
                closePopup={closePopup}
                createCheckoutSession={createCheckoutSession}
              />
            )}
          </DialogContent>
        </Dialog>
      )} */}

      {/* Success and Failure Popups */}
      <UpgradeSuccess isOpen={openSuccessPopup} onClose={() => setOpenSuccessPopup(false)} />
      <FailurePopup isOpen={openFailurePopup} onClose={() => setOpenFailurePopup(false)} />

      <AcceptCouponFromUser
        isPopupOpen={isPopupOpen}
        setPopupOpen={setPopupOpen}
        closePopup={closePopup}
        selectedPlan={selectedPlan}
        selectedPlanPeriod={selectedPlanPeriod}
        createCheckoutSession={createCheckoutSession}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PricingCardSection;
