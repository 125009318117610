const logos = [
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/assets/images/hoot.svg",
    alt: "Hoot",
    width: "w-[80px] 530sm:w-[120px]",
  },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/assets/images/whiz.svg",
    alt: "WhizCamp",
    width: "w-[80px] 530sm:w-[120px]",
  },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ross.svg",
    alt: "Ross",
    width: "w-[70px] 530sm:w-[120px]",
  },
  { src: "https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ocean.svg", alt: "Ocean", width: "" },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/assets/images/oceanAi.svg",
    alt: "Ocean AI",
    width: "",
  },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/assets/images/db.svg",
    alt: "Dubai Petroleum",
    width: "w-[110px] 530sm:w-[140px]",
  },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/assets/images/Hecksow.svg",
    alt: "Hecksow",
    width: "w-[100px] 530sm:w-[170px]",
  },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/assets/images/genisis.svg",
    alt: "Genesis",
    width: "w-[100px] 530sm:w-[120px]",
  },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/am-ma.png",
    alt: "Amma",
    width: "w-auto h-20",
  },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/rate-gain.png",
    alt: "RateGain",
    width: "w-auto h-[100px]",
  },
  {
    src: "https://d2qb2qddg7l6c6.cloudfront.net/legitt-website-v5/assets/images/Gepard_Logistic.png",
    alt: "Gepard Logistics",
    width: "w-[120px] brightness-[120%] 530sm:w-[150px]",
  },
];

export default function CustomersSection() {
  const totalLogos = logos.length;
  const displayCount = totalLogos * 4;

  return (
    <div className="overflow-hidden my-4 800sm:my-10">
      <section className="client-new-container">
        <div className="slider">
          <div className="slide-track flex animate-scroll">
            {Array.from({ length: displayCount }).map((_, index) => {
              const logo = logos[index % totalLogos];
              return (
                <div className="slide inline-block mr-5" key={index}>
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className={`trusted-card-image ${logo.width} grayscale hover:grayscale-0 transition duration-300`}
                    loading="lazy"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
