import { Typography, Box, Grid } from "@mui/material";
import { Star, FormatQuote } from "@mui/icons-material";

const testimonials = [
  {
    id: 1,
    name: "Michael Brown",
    position: "VP of Sales, GreenTech Solutions",
    text: "Legitt AI has completely transformed the way we handle contracts. The electronic signature feature is incredibly intuitive and easy to use, making the signing process much faster. It's a game changer for our small business!",
    rating: 5,
  },
  {
    id: 2,
    name: "Sophia Adams",
    position: "Director, TechConnect Global",
    text: "I’ve used several e-signature tools, but Legitt AI stands out due to its seamless integration with other tools we use. It's efficient, secure, and simple to navigate. Highly recommend!",
    rating: 3.5,
  },
  {
    id: 3,
    name: "Thomas Harris",
    position: "Legal Advisor, Summit Law Group",
    text: "The security features provided by Legitt AI’s electronic signature are top-notch. We were able to ensure that all of our documents are signed with confidence, knowing the system is compliant and protected.",
    rating: 5,
  },
];

const TestimonialSection = () => {
  const TestimonialCard = ({ testimonial }) => (
    <Box
      className="bg-white rounded-3xl p-4 mx-6 relative shadow-lg max-w-[350px] w-full text-gray-700"
      sx={{
        maxWidth: 400, // Set a max width for the card
        mx: "auto", // Center the card
      }}
    >
      <FormatQuote className="absolute text-gray-700 top-4 left-4 transform -scale-x-100" />
      <div className="flex flex-col items-center">
        <p className="text-center bg-gray-100  px-4 justify-center items-center rounded-lg min-h-[200px] text-gray-700 mb-6">
          <div className="text-gray-700 justify-center items-center mt-5">{testimonial.text}</div>
        </p>
        <div className="flex items-center mb-2">
          {[...Array(5)].map((_, i) => (
            <Star
              key={i}
              className={`w-5 h-5 ${
                i < Math.floor(testimonial.rating)
                  ? "text-yellow-400"
                  : i < Math.ceil(testimonial.rating)
                  ? "text-yellow-400" // Half star condition
                  : "text-gray-300"
              }`}
            />
          ))}
        </div>
        <h3 className="font-bold text-xl mb-1">{testimonial.name}</h3>
        <p className="text-gray-600">{testimonial.position}</p>
      </div>
      <FormatQuote className="absolute text-gray-700 bottom-4 right-4" />
    </Box>
  );

  return (
    <div className="w-full bg-gray-100 py-8 my-28">
      <div className="sm:px-[25px] md:px-[60px] lg:px-[100px]">
        <div className="mx-auto w-[95%]">
          <p className="text-center lg:text-left my-[20px] text-gray-700 font-bold text-[20px] 610sm:text-[24px] 768sm:text-[26px] 968sm:text-[34px]">
            What Our Clients Are Saying
          </p>
          <hr className="w-1/6 hidden xl:block border-[1px] border-orange-200" />
        </div>
        <div className="my-10 flex flex-wrap justify-center align-center gap-8  xl:gap-4">
          {testimonials.map((testimonial) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={testimonial.id}
              sx={{
                display: "flex",
                justifyContent: "center", // Center the cards
              }}
            >
              <TestimonialCard testimonial={testimonial} />
            </Grid>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
