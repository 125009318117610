import React, { useEffect, useState } from "react";

import NewLandingPageHeroSection from "./NewLandingPageHeroSection";
import EmailCollectionPopup from "../../Components/EmailCollectionPopup";
export default function NewLandingPage() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleClosePopup = () => setIsPopupOpen(false);

  const handleSubmitEmail = (email) => {
    // Handle email submission (e.g., send to server)
    console.log("Submitted email:", email);
    setIsPopupOpen(false);
  };

  const handleMouseLeave = () => {
    setIsPopupOpen(true);
  };

  const handleMouseOut = (event) => {
    if (event.clientY < 0) {
      setIsPopupOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("mouseleave", handleMouseLeave);
    window.addEventListener("mouseout", handleMouseOut);
    return () => {
      window.removeEventListener("mouseleave", handleMouseLeave);
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, []);

  return (
    <div>
      <NewLandingPageHeroSection />
      {/* <EmailCollectionPopup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        onSubmitEmail={handleSubmitEmail}
      /> */}
    </div>
  );
}
