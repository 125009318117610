import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

const EnterpriseContactForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    companyName: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const response = await fetch("https://prodapi2.legittai.com/api/notification/sendMail", {
        method: "POST",
        body: JSON.stringify({
          ...formData,
          requestedFor: 'internal-team',
          reason: "Electronic Signature Custom Plan Request",
          senderEmail: "harshdeep.rapal@legittai.com",
        }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });

      if (response.ok) {
        setSuccess(true);
        setTimeout(() => {
          onClose();
          setSuccess(false);
          setFormData({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            companyName: "",
          });
        }, 2000);
      } else {
        setError("Failed to send message. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Contact Us for Enterprise Plan</DialogTitle>
      <DialogContent>
        {success ? (
          <div className="text-green-600 py-4 text-center">
            Thank you! We'll get back to you soon.
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4 py-4">
            <TextField
              required
              fullWidth
              name="firstname"
              label="First Name"
              value={formData.firstname}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              name="lastname"
              label="Last Name"
              value={formData.lastname}
              onChange={handleChange}
            />
            <TextField
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              required
              fullWidth
              name="phone"
              label="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              name="companyName"
              label="Company Name"
              value={formData.companyName}
              onChange={handleChange}
            />
            {error && <div className="text-red-500 text-sm">{error}</div>}
            <div className="flex justify-end space-x-2 pt-4">
              <Button onClick={onClose} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                className="common-button"
              >
                {isLoading ? <CircularProgress size={24} /> : "Send"}
              </Button>
            </div>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EnterpriseContactForm;