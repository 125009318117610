import React from 'react';
// import { Link } from "react-router-dom";
import { Divider } from "@mui/material";

export default function Footer() {
  return (
    <div>
      <footer className="bg-gray-100 text-gray-700">
        <div className="mx-auto px-[4%] lg:px-[8%] py-8 lg:pt-12">
          <div className="grid gap-8 grid-cols-2 md:grid-cols-4 lg:col-span-2 lg:px-[6%] pb-6 lg:pb-8">
            <div className="text-left">
              <p className="text-[16px] font-medium">Products</p>

              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="transition hover:text-gray-700/75"
                    href="https://legittai.com/contract-management-software"
                  >
                    Contract Management Software
                  </a>
                </li>

                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/electronic-signature">
                    Electronic Signature
                  </a>
                </li>

                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/legitt-lite">
                    Legitt Lite
                  </a>
                </li>

                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/legitt-pro">
                    Legitt Pro
                  </a>
                </li>
                <li>
                  <a
                    className="transition hover:text-gray-700/75"
                    href="https://legittai.com/repo-analyzer"
                  >
                    Repo Analyzer
                  </a>
                </li>

                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/contract-generator">
                    AI Contract Generator
                  </a>
                </li>
                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/contract-review">
                    AI Contract Review
                  </a>
                </li>
                <li>
                  <a
                    className="transition hover:text-gray-700/75"
                    href="https://legittai.com/free-contract-templates"
                  >
                    Free Contract Templates
                  </a>
                </li>
              </ul>
            </div>

            <div className="text-left">
              <p className="text-[16px] font-medium">Company</p>

              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/about-us">
                    About Us
                  </a>
                  {/* <Link className="transition hover:text-gray-700/75" to="/about-us">
                      About Us
                    </Link> */}
                </li>

                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/privacy-policy">
                    Privacy Policy
                  </a>
                  {/* <Link
                    className="transition hover:text-gray-700/75"
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link> */}
                </li>

                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/terms-and-conditions">
                    Terms & Conditions
                  </a>
                  {/* <Link
                    className="transition hover:text-gray-700/75"
                    to="/terms-and-conditions"
                  >
                    Terms & Conditions
                  </Link> */}
                </li>

                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/job-list">
                    Jobs & Careers
                  </a>
                  {/* <Link className="transition hover:text-gray-700/75" to="/job-list">
                    Jobs & Careers
                  </Link> */}
                </li>
                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/affiliate-program">
                    Affiliate Program
                  </a>
                </li>
              </ul>
            </div>

            <div className="text-left">
              <p className="text-[16px] font-medium">Resources</p>

              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="transition hover:text-gray-700/75"
                    href="https://legittai.com/blog/"
                  >
                    Blog
                  </a>
                </li>

                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/demo">
                    {" "}
                    Get A Demo
                  </a>
                  {/* <Link className="transition hover:text-gray-700/75" to="/demo">
                    {" "}
                    Get a Demo
                  </Link> */}
                </li>

                <li>
                  <a
                    className="group flex sm:flex-row gap-1.5 justify-start items-center"
                    href="https://legittai.com/videos"
                  >
                    <span className="text-left transition group-hover:text-gray-700/75">
                      Videos
                    </span>

                    <span className="relative flex h-2 w-2">
                      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-teal-400 opacity-75"></span>
                      <span className="relative inline-flex h-2 w-2 rounded-full bg-teal-500"></span>
                    </span>
                  </a>
                  {/* <Link
                    className="group flex sm:flex-row gap-1.5 justify-start items-center"
                    to="/videos"
                  >
                    <span className="text-left transition group-hover:text-gray-700/75">
                      Videos
                    </span>

                    <span className="relative flex h-2 w-2">
                      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-teal-400 opacity-75"></span>
                      <span className="relative inline-flex h-2 w-2 rounded-full bg-teal-500"></span>
                    </span>
                  </Link> */}
                </li>
                <li>
                  <a className="transition hover:text-gray-700/75" href="https://legittai.com/glossary">
                    Glossary
                  </a>
                  {/* <Link className="transition hover:text-gray-700/75" to="/glossary">
                    Glossary
                  </Link> */}
                </li>

                <li>
                  <a
                    className="transition hover:text-gray-700/75"
                    href="https://legittai.com/product-tour"
                  >
                    Product Tour
                  </a>
                </li>

                {/* <li>
                    <a
                      className="transition hover:text-gray-700/75"
                      href="/support"
                    >
                      Support
                    </a>
                  </li> */}

                {/* <li>
                    <a
                      className="transition hover:text-gray-700/75"
                      href="/release-notes"
                    >
                      Release Notes
                    </a>
                  </li> */}
              </ul>
            </div>

            <div className="text-left">
              <p className="text-[16px] font-medium">Contact Us</p>

              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                    href="mailto:info@legittai.com"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="size-5 shrink-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>

                    <span className="flex-1">info@legittai.com</span>
                  </a>
                </li>

                <li>
                  <a
                    className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                    href="https://wa.me//+16143448755"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="size-5 shrink-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>

                    <span className="flex-1">+1-614-344-8755</span>
                  </a>
                </li>

                {/* <li>
                  <a
                    className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                    href="https://t.me/+1-614-344-8755"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="size-5 shrink-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>

                    <span className="flex-1">+1-614-344-8755</span>
                  </a>
                </li> */}

                <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <address className="-mt-0.5 flex-1 not-italic">
                    1007 N ORANGE ST. 4TH FLOOR 839 Wilmington Delaware USA -19801
                  </address>
                </li>
              </ul>
            </div>
          </div>
          <Divider className="" />
          <div className="pt-6 lg:pt-8 flex justify-center lg:justify-between px-[2%]">
            <div className="hidden lg:block">
              <a href="https://legittai.com/" className="logo flex items-center justify-start gap-4">
                <img
                  src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/logo.png"
                  alt="Logo"
                  className="h-[53px]"
                />
                {/* <h1 className="text-[20px]">Legitt</h1> */}
              </a>
            </div>
            <div className="follow-us flex flex-between gap-4 items-center">
              <div>
                <h4 className="text-[20px]">Follow Us:</h4>
              </div>
              <div>
                <ul className="flex justify-center gap-2 sm:justify-start md:gap-4">
                  <li>
                    <a href="https://www.facebook.com/Legitthq" rel="noopener" target="_blank">
                      <span className="sr-only">Facebook</span>
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a href="https://www.instagram.com/legitt_hq" rel="noopener" target="_blank">
                      <span className="sr-only">Instagram</span>
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a href="https://twitter.com/Legitthq" rel="noopener" target="_blank">
                      <span className="sr-only">Twitter</span>
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/legitthq/"
                      rel="noopener"
                      target="_blank"
                    >
                      <span className="sr-only">Linked In</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" />
                        <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" />
                        <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <Link to="/">
                <h1 className="hidden sm:block text-[20px]">Legitt</h1>
              </Link> */}
          </div>
        </div>
        <div className=" bg-cyan-900 py-2">
          <div className="text-center italic text-white">
            <p className="sm:order-first sm:mt-0">
              &copy; Onitt & Upepo Technology Labs Inc 2024. All rights reserved
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
