import React, { useState } from "react";

interface FAQ {
  question: string;
  answer: string;
}

const FaqsSection: React.FC = () => {
  const [openFAQ, setOpenFAQ] = useState<number | null>(null);
  const [showAll, setShowAll] = useState<boolean>(false);

  const toggleFAQ = (index: number): void => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const handleShowMore = (): void => {
    setShowAll(!showAll);
  };

  const faqs: FAQ[] = [
    {
      question: "How do I upload a document to the eSign application?",
      answer:
        "To upload a document, first, sign in to your account. Once you access the dashboard, you will notice an option for document upload. Select this feature to proceed. You then have the flexibility to either upload a document from your device or explore and choose from our extensive library of thousands of templates available for your convenience."
    },
    {
      question: "Can I use the eSign application's templates for my documents?",
      answer:
        "Certainly! To streamline your document creation process, we offer an extensive selection of templates. Simply select the one that best fits your requirements and customize it to meet your specific needs. This feature is designed to make the document creation both efficient and user-friendly.",
    },
    {
      question: "How do I add signatories to my document?",
      answer:
        "Once you have uploaded your document, you can designate the signatories by entering their email addresses or names. Following this, our eSign application will seamlessly and automatically dispatch notifications to these individuals, prompting them to sign the document. This process ensures a smooth and efficient signing experience for all parties involved.",
    },
    {
      question: "What happens if a signatory doesn't sign the document promptly?",
      answer:
        "Our eSign application efficiently manages the signing process by sending automated reminders to signatories until they finalize their signatures. This feature is designed to ensure that your documents are signed promptly and without delay, enhancing the overall efficiency of the document completion process.",
    },
    {
      question: `Tell me more about the "LegittAI" AI functionalities.`,
      answer:
        "Legitt AI, an innovative platform, offers a suite of features designed to streamline business operations. It boasts advanced AI integration, enabling smart contract management and decision-making automation. The platform is enhanced with robust security protocols, being ISO 27001, GDPR, and SOC 2 Type 2 compliant. Its user-friendly interface facilitates seamless collaboration among team members. Legitt AI's document management system is highly efficient, offering an extensive library of templates and a sophisticated eSign application for quick document turnover. Additionally, it includes automated reminders to expedite the signing process, ensuring timely completion of tasks. This combination of AI and security makes Legitt AI a comprehensive solution for modern businesses.",
    },
    {
      question: `How can I access the "LegittAI" functionality in the eSign application?`,
      answer:
        `The "LegittAI" feature is readily accessible during your document editing process. While working on a document, you can easily tap on the "LegittAI" button. This action activates the system to offer valuable insights and practical suggestions, designed to enhance the quality and effectiveness of your document.`,
    },
    {
      question: "Is my data and documents secure within the eSign application?",
      answer:
        "Absolutely, safeguarding your data and documents is our top priority. Our application employs robust encryption and adheres to industry-leading best practices, ensuring comprehensive protection of your information. We are fully compliant with GDPR, ISO 27001, and SOC 2 standards, further bolstering our commitment to data security. Additionally, our use of AWS Cryptography signifies our dedication to utilizing advanced security technologies. For a detailed understanding of our security protocols and measures, we invite you to review our comprehensive privacy and security policy.",
    },
    {
      question: "How do I track the status of my documents and signatures?",
      answer:
        "Effortlessly monitor the progress of your documents through our user-friendly dashboard. This feature provides real-time updates on each document's status, clearly indicating which signatories have completed their signatures and which ones remain pending. Additionally, you will receive timely notifications regarding the status of your documents, keeping you informed every step of the way and ensuring you're always up-to-date on the signing process. This comprehensive tracking system is designed for your convenience and peace of mind.",
    },
  ];

  const displayedFAQs = showAll ? faqs : faqs.slice(0, 3);

  return (
    <div
      className="px-8 mb-[100px] max-w-5xl mx-auto flex flex-col gap-12 mt-[100px] text-gray-700"
      itemScope
      itemType="https://schema.org/FAQPage"
    >
      <div className="flex flex-col text-left">
        <h2 className="text-gray-700 font-semibold text-[20px] 610sm:text-[24px] 768sm:text-[26px] 968sm:text-[38px]">
          Frequently Asked Questions
        </h2>
      </div>
      <ul>
        {displayedFAQs.map((faq, index) => (
          <li key={index}>
            <button
              itemScope
              itemProp="mainEntity"
              itemType="https://schema.org/Question"
              className="relative flex flex-col gap-2 w-full py-5 text-[16px] font-semibold text-left border-t md:text-[16px] border-base-content/10"
              aria-expanded={openFAQ === index}
              onClick={() => toggleFAQ(index)}
            >
              <div className="flex justify-between items-center w-full">
                <h3 className="flex-1 text-[16px] font-medium" itemProp="name">
                  {faq.question}
                </h3>
                <svg
                  className="flex-shrink-0 w-4 h-4 ml-auto fill-current"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="7"
                    width="16"
                    height="2"
                    rx="1"
                    className={`transform origin-center transition duration-200 ease-out ${openFAQ === index ? "rotate-90" : ""
                      }`}
                  ></rect>
                  <rect
                    y="7"
                    width="16"
                    height="2"
                    rx="1"
                    className="transform origin-center rotate-90 transition duration-200 ease-out"
                  ></rect>
                </svg>
              </div>
              <div
                itemScope
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer"
                className="transition-all duration-300 ease-in-out overflow-hidden"
                style={{ maxHeight: openFAQ === index ? "500px" : "0" }}
              >
                <div className="pb-5 leading-relaxed">
                  <div
                    className="space-y-2 leading-relaxed font-normal text-[16px]"
                    itemProp="text"
                  >
                    {faq.answer}
                  </div>
                </div>
              </div>
            </button>
          </li>
        ))}
      </ul>
      {!showAll && (
        <div className="flex justify-end">
          <button
            onClick={handleShowMore}
            className="cursor-pointer bg-[#06526D] px-3 py-2 rounded-md text-white tracking-wider shadow-xl animate-bounce hover:animate-none"
          >
            <svg
              className="w-5 h-5"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default FaqsSection;
