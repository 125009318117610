import React, { useState, useRef, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import {
  TextField,
  Box,
  Container,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import Swal from "sweetalert2";
import { isValidPhoneNumber } from "react-phone-number-input";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Close } from "@mui/icons-material";
import soc2 from "../../../assets/images/soc_2.png";

const Popup = ({ isOpen, onClose, formData, handleCloseCalendlyPopup }) => {
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("Profile page viewed"),
    onDateAndTimeSelected: () => console.log("Date and time selected"),
    onEventTypeViewed: () => console.log("Event type viewed"),
    onEventScheduled: (e) => console.log("Event scheduled:", e.data.payload),
    onPageHeightResize: (e) => console.log("Page height resized:", e.data.payload.height),
  });

  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseCalendlyPopup}
      fullWidth
      maxWidth="md"
      className="demo-popup-calendly-schedule"
    >
      <DialogTitle>
        <div className="flex justify-between items-center">
          <div>
            <div className="text-[18px] text-[#000] font-semibold">
              We have received your demo request successfully.
            </div>
            <div className="text-[14px] font-medium">Book your own time slot.</div>
          </div>
          <IconButton onClick={handleCloseCalendlyPopup}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <InlineWidget
          url="https://savvycal.com/harshdeep/1381cd4b"
          prefill={{
            name: `${formData.firstname} ${formData.lastname}`,
            email: formData.email,
          }}
          styles={{
            minWidth: "520px",
            height: "700px",
            borderRadius: "50px",
            zIndex: 60,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const ScheduleDemo = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyName: "",
    phone: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const sectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleCloseCalendlyPopup = () => {
    setIsPopupOpen(false);
    setFormData({
      firstname: "",
      lastname: "",
      email: "",
      companyName: "",
      phone: "",
    });
  };

  const handleScroll = () => {
    if (sectionRef.current) {
      const { top } = sectionRef.current.getBoundingClientRect();
      const isInView = top < window.innerHeight * 0.5 && top >= 0;
      setIsScrolled(isInView);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value ?? "" });
  };

  const scheduleDemo = async () => {
    const { email, phone } = formData;
    setIsLoading(true);
    if (!validateEmail(email)) {
      Swal.fire("Error!", "Email is invalid!", "error");
      setIsLoading(false);
      return;
    }
    if (!isValidPhoneNumber(phone)) {
      Swal.fire("Error!", "Phone number is invalid!", "error");
      setIsLoading(false);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch("https://prodapi2.legittai.com/api/notification/sendMail", {
        method: "POST",
        body: JSON.stringify({
          firstname: formData.firstname,
          lastname: formData.lastname,
          email: formData.email,
          phone: formData.phone,
          companyName: formData.companyName,
          senderEmail: "harshdeep.rapal@legittai.com",
        }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });

      if (response.ok) {
        setIsSubmitting(false);
        setIsPopupOpen(true);
        setIsLoading(false);
      } else {
        Swal.fire(
          "Error!",
          "Something went wrong while sending email. Try again or email at info@legittai.com!",
          "error"
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsSubmitting(false);
      setIsLoading(false);
      Swal.fire(
        "Error!",
        "Something went wrong while sending email. Try again or email at info@legittai.com!",
        "error"
      );
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="px-0">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        className={`form-container flex flex-col gap-2 ${isScrolled ? "scrolled" : ""}`}
      >
        <Box className="grid grid-cols-1 gap-2 513sm:grid-cols-2">
          <TextField
            required
            fullWidth
            className="demo-form-input-fields"
            id="firstname"
            label="First Name"
            variant="outlined"
            value={formData.firstname}
            onChange={handleChange}
          />
          <TextField
            required
            fullWidth
            className="demo-form-input-fields"
            id="lastname"
            label="Last Name"
            variant="outlined"
            value={formData.lastname}
            onChange={handleChange}
          />
        </Box>
        <TextField
          required
          fullWidth
          className="demo-form-input-fields"
          id="companyName"
          label="Company"
          variant="outlined"
          value={formData.companyName}
          onChange={handleChange}
        />
        <TextField
          required
          fullWidth
          className="demo-form-input-fields"
          id="email"
          label="Email Address"
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
        />

        <PhoneInput
          className="w-full bg-[#fff] py-[15px] px-[11px] demo-contact-number-text-field"
          placeholder="Enter phone number"
          value={formData.phone}
          onChange={handlePhoneChange}
          defaultCountry="IN"
          required
        />
        <div className="flex justify-center items-center mt-[10px]">
          <button
            className="common-button"
            onClick={scheduleDemo}
            disabled={isSubmitting || isLoading}
          >
            {isSubmitting || isLoading ? (
              <div>
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              "Get A Demo"
            )}
          </button>
        </div>
        <div className="mt-2 text-[0.7rem] lg:text-[0.8rem] font-normal">
          By scheduling demo, you accept our{" "}
          <a
            className=" font-semibold"
            style={{ textDecoration: "underline" }}
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>{" "}
          &{" "}
          <a
            className=" font-semibold "
            style={{ textDecoration: "underline" }}
            href="/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms and conditions
          </a>
          .
        </div>
      </Box>
      <Popup
        isOpen={isPopupOpen}
        onClose={handleCloseCalendlyPopup}
        formData={formData}
        handleCloseCalendlyPopup={handleCloseCalendlyPopup}
      />
    </Container>
  );
};

const Demo = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const sectionRef = useRef(null);

  const handleScroll = () => {
    if (sectionRef.current) {
      const { top } = sectionRef.current.getBoundingClientRect();
      const isInView = top < window.innerHeight * 0.5 && top >= 0;
      setIsScrolled(isInView);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={`flex  flex-wrap mb-[100px] lg:flex-nowrap ${
          isScrolled ? "px-[5%]" : "px-[4%]"
        } transition-all duration-500 ease-in-out justify-center items-center rounded-lg gap-6`}
        ref={sectionRef}
      >
        <div className="w-full lg:w-[100%]">
          <div
            className={`relative isolate overflow-hidden ${
              isScrolled
                ? "bg-gradient-to-b from-[#06526D] to-[#069fc0] text-white"
                : "bg-gray-100 text-gray-700"
            } py-16 sm:py-24 rounded-[50px] transition-all duration-300 ease-in-out`}
          >
            <div className="mx-auto px-[15px] md:px-[30px] justify-center items-center lg:px-[50px] xl:px-[50px]">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 justify-center items-center gap-y-16 lg:max-w-[1400px] lg:grid-cols-2">
                <div className="md:max-w-[700px] lg:max-w-[600px] w-auto justify-center items-center">
                  <h2
                    className={` text-center text-[16px] mb-[20px] md:text-[24px] lg:text-[20px] xl:text-[24px] 2xl:text-[28px] tracking-tight ${
                      isScrolled ? "text-white" : "text-gray-700"
                    } sm:text-[24px]`}
                  >
                    Schedule a Demo & Discussion with an Expert
                  </h2>

                  <ScheduleDemo />
                </div>
                <dl className="">
                  <div className="w-full">
                    <div className="font-bold text-left text-[36px] sm:text-[44px] lg:text-[40px] mb-[10px] rounded-lg mt-0 ml-0">
                      Talk to our Experts
                    </div>
                    <p className="text-[20px] text-left sm:text-[20px] lg:text-[24px] mt-0 mb-[40px] ml-0">
                      Schedule a personalized consultation
                    </p>

                    <div className="mt-6 space-y-4">
                      {/* Step 1 */}
                      <div className="flex items-center space-x-4">
                        <span
                          className={`text-white ${
                            isScrolled ? "bg-black" : "bg-[#06526D]"
                          } min-w-8 max-w-8 h-8 flex items-center justify-center rounded-full font-bold`}
                        >
                          1
                        </span>
                        <p className="text-[16px] sm:text-[14px] md:text-[16px] text-left lg:text-[18px] xl:text-[20px]">
                          Schedule
                        </p>
                      </div>

                      {/* Step 2 */}
                      <div className="flex items-center space-x-4">
                        <span
                          className={`text-white ${
                            isScrolled ? "bg-black" : "bg-[#06526D]"
                          } min-w-8 max-w-8 h-8 flex items-center justify-center rounded-full font-bold`}
                        >
                          2
                        </span>
                        <p className="text-[16px] sm:text-[14px] md:text-[16px] text-left lg:text-[18px] xl:text-[20px] ">
                          Discuss your requirement + See product Demo
                        </p>
                      </div>

                      {/* Step 3 */}
                      <div className="flex items-center justify-top space-x-4">
                        <span
                          className={`text-white ${
                            isScrolled ? "bg-black" : "bg-[#06526D]"
                          } min-w-8 max-w-8 h-8 flex items-center justify-center rounded-full font-bold`}
                        >
                          3
                        </span>
                        <p className="text-[16px] sm:text-[14px] md:text-[16px] text-left lg:text-[18px] xl:text-[20px]">
                          Discover how we can simplify your sales process to save time and close
                          deals faster.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="w-full">
  <div className="font-bold text-[32px] sm:text-[40px] lg:text-[48px] rounded-lg">
    Talk to our Experts
  </div>
  <p className="text-[16px] sm:text-[16px] lg:text-[20px] font-bold mt-4">
    Schedule a personalized consultation
  </p>
  <ol className="text-[14px] sm:text-[16px] lg:text-[16px] mt-2">
  <li>Schedule</li></ol>
  <p className="text-[14px] sm:text-[16px] lg:text-[16px] mt-2">
  2. Discuss your problem + See product Demo</p>
  <p className="text-[14px] sm:text-[16px] lg:text-[16px] mt-2">3. We will work with you to streamline Sales Proposals and Contracts
  </p>
</div> */}
                  <div className="flex flex-wrap bg-white gap-1 xs:gap-2 items-center justify-around mt-[50px] sm:mt-[50px] md:mt-[30px] p-2 rounded-[12px] w-[100%]">
                    <img
                      src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ISO27701.png"
                      alt="iso27701"
                      className="w-auto h-[3rem] sm:h-[3.5rem] lg:h-[4rem] xl:h-[5.5rem]"
                      loading="lazy"
                    />
                    <img
                      src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ISO27001.png"
                      alt="iso27001"
                      className="w-auto h-[3rem] sm:h-[3.5rem]  lg:h-[4rem] xl:h-[5rem]"
                      loading="lazy"
                    />

                    <img
                      src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/GdprCertification.png"
                      alt="gdpr"
                      className="w-auto h-[3rem] sm:h-[3.5rem] lg:h-[4rem] xl:h-[5rem]"
                      loading="lazy"
                    />
                    <img
                      src={soc2}
                      alt="soc"
                      className="w-auto h-[3rem] sm:h-[3.5rem] lg:h-[4rem] xl:h-[5rem]"
                      loading="lazy"
                    />
                    {/* <img
    src="https://d2qb2qddg7l6c6.cloudfront.net/assets/images/ba.webp"
    alt="ba"
    className="w-auto h-6"
  />
  <img
    src={AAIELogo}
    alt="aaie"
    className="w-auto h-[3rem]"
  /> */}
                  </div>
                </dl>
              </div>
            </div>
            {/* <div
              className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
              aria-hidden="true"
            >
              <div
                className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              ></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
